import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "IMD Lamination",
  "description": "For laminating plastics during injection molding.",
  "author": "Orkun Zafer Ozturk",
  "categories": ["mechanical"],
  "date": "2022-01-01T00:00:00.000Z",
  "featured": false,
  "tags": ["", "", "", "", ""],
  "relatedArticles": ["Injection-Molding", "Powder-Coating"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`In-mold Lamination or In-mold Decoration (IMD) adds a layer of laminating film into the mold during the injection molding process. `}</p>
    <p>{`The process is often preferred since it realizes fully automatic production, which improves efficiency and reduces costs. It also gets coloring done in a single step. In-mold lamination, unlike regular laminating processes (i.e., post-mold lamination), gives a more durable finish. `}</p>
    <p>{`The advantages of using in-mold lamination include:`}</p>
    <ul>
      <li parentName="ul">{`The laminated label doesn't fall off as easily,  `}</li>
      <li parentName="ul">{`It's not easily damaged and torn  `}</li>
      <li parentName="ul">{`Generally water and oil proof  `}</li>
      <li parentName="ul">{`Mildewproof  `}</li>
      <li parentName="ul">{`Acid and alkali resistant  `}</li>
      <li parentName="ul">{`Doesn't shrink at low temperatures  `}</li>
    </ul>
    <p>{`The lamination material needs to be chosen according to the material of the workpiece, as melting temperatures and cycle times need to be matched within the mold.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      